








import { Component, Vue } from "vue-property-decorator";
import RevisarReservaChapuzon from "@/components/Reserva/RevisarReservaChapuzon.vue";

@Component({
  components: {
    RevisarReservaChapuzon
  }
})
export default class RevisarReservaChapuzonView extends Vue {}
