


























































































































































































import { Component, Vue, Prop, Watch, Mixins } from "vue-property-decorator";
import FormValidator from "@/components/mixins/FormValidator.ts";
import { jsPDF } from "jspdf";

@Component
export default class Reserva extends Mixins(FormValidator) {
  private codReserva = "000000001";
  private reserva: any = {};
  private validRut = false;
  private codigo = "";
  private formattedFecha = "";
  private formattedRut = "";
  private reservaUsuario: any = {
    rut: "",
    nombre: "",
    rutR: "",
    nombreR: "",
    telefono: "",
    email: "",
    fecha: "",
    direccion: "",
    estado: "",
    qr: "",
    codigo: ""
  };
  private canvas: any = null;
  private img = new Image();

  private mounted() {
    if (
      this.$router.currentRoute.params.codigo &&
      this.$router.currentRoute.params.codigo != ""
    ) {
      this.codigo = this.$router.currentRoute.params.codigo;
      this.getReservaEvento();
    } else {
      this.routerGo("Error");
    }
  }
  private getReservaEvento() {
    this.$q.loading.show({
      delay: 100 // ms
    });
    this.$axios
      .get("/navidad/2024/ticket?codigo=" + this.codigo)
      .then((res: any) => {
        this.reservaUsuario = res.data;
        this.formatRut(this.reservaUsuario.rut);
        const base = process.env.VUE_APP_BASE_URL;
        this.reservaUsuario.foto = require("@/assets/Reservas/banner_desktop.png");
        this.reservaUsuario.foto_mobile = require("@/assets/Reservas/banner_mobile.png");
        this.$q.loading.hide();
        const qr = new Image();
        if (this.reservaUsuario.qr) {
          qr.src = `data: ${this.reservaUsuario.qr}`;
          this.reservaUsuario.qr = qr;
        }
      })
      .catch((err: any) => {
        this.$q.loading.hide();
        this.routerGo("Error");
      });
  }
  private printImage() {
    this.canvas = document.getElementById("myCanvas") as any;
    this.img.src = require("@/assets/Reservas/banner_pdf.png");
    // this.img.crossOrigin = "anonymous";
    this.img.onload = () => {
      if (this.canvas) {
        let addY = 150;
        let imgHeight = 250;
        this.canvas.width = 1157;
        this.canvas.height = 1637;
        const ctx = this.canvas.getContext("2d");
        ctx.fillStyle = "white";
        ctx.fillRect(0, 0, this.canvas.width, this.canvas.height);
        ctx.drawImage(this.img, 0, 0, 1157, imgHeight);
        ctx.textAlign = "start";
        // To change the color on the rectangle, just manipulate the context
        ctx.strokeStyle = "#5F6A6A";
        ctx.fillStyle = "white";
        this.roundRect(ctx, 49, 160 + addY, 1060, 700, 20, true, true);

        ctx.textAlign = "center";
        ctx.fillStyle = "#5F6A6A";
        ctx.font = "bold 25px Verdana";
        ctx.fillText(
          "Comprobante de Inscripción",
          // this.reservaUsuario.nombre_evento,
          this.canvas.width / 2 - 5,
          140 + addY
        );

        ctx.textAlign = "start";

        ctx.fillStyle = "#02436b";
        ctx.font = "bold 20px Verdana";
        ctx.fillText("Reserva para: ", 120, 220 + addY);
        ctx.fillStyle = "#5F6A6A";
        ctx.fillText(this.reservaUsuario.nombre_evento, 120, 250 + addY);

        ctx.fillStyle = "#02436b";
        ctx.font = "bold 20px Verdana";
        ctx.fillText("Nombre: ", 120, 310 + addY);
        ctx.fillStyle = "#5F6A6A";
        ctx.fillText(this.reservaUsuario.nombre, 120, 340 + addY);

        ctx.fillStyle = "#02436b";
        ctx.fillText("C.I: ", 120, 400 + addY);
        ctx.fillStyle = "#5F6A6A";
        ctx.fillText(this.reservaUsuario.rut, 120, 430 + addY);

        ctx.fillStyle = "#02436b";
        ctx.font = "bold 20px Verdana";
        ctx.fillText("Nombre Representante: ", 120, 480 + addY);
        ctx.fillStyle = "#5F6A6A";
        ctx.fillText(this.reservaUsuario.nombreR, 120, 510 + addY);

        ctx.fillStyle = "#02436b";
        ctx.fillText("C.I Representante: ", 120, 560 + addY);
        ctx.fillStyle = "#5F6A6A";
        ctx.fillText(this.reservaUsuario.rutR, 120, 590 + addY);

        ctx.fillStyle = "#02436b";
        ctx.fillText("Fecha Aprobación: ", 120, 640 + addY);
        ctx.fillStyle = "#5F6A6A";
        ctx.fillText(this.reservaUsuario.fecha, 120, 670 + addY);

        ctx.drawImage(this.reservaUsuario.qr, 740, 200 + addY, 300, 300);
        ctx.fillStyle = "#5F6A6A";
        ctx.textAlign = "center";
        ctx.font = "bold 18px Verdana";
        ctx.fillText(this.reservaUsuario.codigo, 890, 520 + addY);

        ctx.textAlign = "center";
        ctx.fillStyle = "#5F6A6A";
        ctx.fillText(
          "Recuerda presentar tu identificación.",
          this.canvas.width / 2 - 5,
          // 980
          770 + addY
        );
        ctx.fillText("¡Te Esperamos!", this.canvas.width / 2 - 5, 800 + addY);

        let width = this.canvas.width;
        let height = this.canvas.height;
        var pdf = new jsPDF("p", "pt", [595.28, 841.89]);

        //then we get the dimensions from the 'pdf' file itself
        width = pdf.internal.pageSize.getWidth();
        height = pdf.internal.pageSize.getHeight();
        pdf.addImage(
          this.canvas.toDataURL("image/png"),
          "PNG",
          0,
          0,
          width,
          height,
          undefined,
          "FAST"
        );
        pdf.save("comprobante.pdf");
      }
    };
  }
  private formatRut(newValue: string) {
    newValue = newValue.trim();
    newValue = newValue.replace(/\./g, "").replace(/-/g, "");
    if (newValue.length > 5) {
      const rut = newValue
        .substring(0, newValue.length - 1)
        .replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1.");
      const dv = newValue.substring(newValue.length - 1);
      this.formattedRut = `${rut}-${dv}`;
    } else {
      this.formattedRut = newValue
        .replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1.")
        .toUpperCase();
    }
  }
  private routerGo(name: string) {
    if (name && name != "") {
      if (this.$router.currentRoute.name == name) {
        this.$router.go(0);
      } else {
        this.$router.push({ name: name }).catch(err => {
          console.log(err);
        });
      }
    }
  }
  private roundRect(
    ctx: any,
    x: any,
    y: any,
    width: any,
    height: any,
    radius: any = 5,
    fill: any,
    stroke: any = true
  ) {
    if (typeof radius === "number") {
      radius = { tl: radius, tr: radius, br: radius, bl: radius };
    } else {
      var defaultRadius = { tl: 0, tr: 0, br: 0, bl: 0 };
      for (var side in defaultRadius) {
        radius[side] = radius[side] || defaultRadius[side];
      }
    }
    ctx.beginPath();
    ctx.moveTo(x + radius.tl, y);
    ctx.lineTo(x + width - radius.tr, y);
    ctx.quadraticCurveTo(x + width, y, x + width, y + radius.tr);
    ctx.lineTo(x + width, y + height - radius.br);
    ctx.quadraticCurveTo(
      x + width,
      y + height,
      x + width - radius.br,
      y + height
    );
    ctx.lineTo(x + radius.bl, y + height);
    ctx.quadraticCurveTo(x, y + height, x, y + height - radius.bl);
    ctx.lineTo(x, y + radius.tl);
    ctx.quadraticCurveTo(x, y, x + radius.tl, y);
    ctx.closePath();
    if (fill) {
      ctx.fill();
    }
    if (stroke) {
      ctx.stroke();
    }
  }
}
