








import { Component, Vue } from "vue-property-decorator";
import RevisarReservaNavidad from "@/components/Reserva/RevisarReservaNavidad.vue";

@Component({
  components: {
    RevisarReservaNavidad
  }
})
export default class RevisarReservaNavidadView extends Vue {}
