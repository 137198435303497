








import { Component, Vue } from "vue-property-decorator";
import RevisarReservaChapuzon2024 from "@/components/Reserva/RevisarReservaChapuzon2024.vue";

@Component({
  components: {
    RevisarReservaChapuzon2024
  }
})
export default class RevisarReservaChapuzon2024View extends Vue {}
